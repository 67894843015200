<script setup>
import { ref } from 'vue';
import { loginUser } from '@services/db';
import Cookies from 'js-cookie';

const form = ref({
  email: '',
  password: '',
});

async function login() {
  const user = await loginUser(form.value);
  if (user) {
    Cookies.set('pbToken', user.token, { secure: false, httpOnly: false, sameSite: 'Strict' });
    window.location.href = '/admin';
  } else {
  }
}
</script>

<template>
  <form
    class="space-y-5"
    action="#"
    method="POST">
    <div>
      <label
        for="email"
        class="block text-sm font-medium leading-6 text-gray-900"
        >Email address</label
      >
      <div class="mt-2">
        <input
          id="email"
          name="email"
          type="email"
          autocomplete="email"
          required=""
          v-model="form.email"
          class="flex w-full max-w-3xl rounded-lg border-0 py-3 pl-3 text-base-900 shadow-sm ring-1 ring-inset ring-slate-200 placeholder:text-slate-400 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-600 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-base sm:leading-6" />
      </div>
    </div>

    <div>
      <label
        for="password"
        class="block text-sm font-medium leading-6 text-gray-900"
        >Password</label
      >
      <div class="mt-2">
        <input
          id="password"
          name="password"
          type="password"
          v-model="form.password"
          autocomplete="current-password"
          required=""
          class="flex w-full max-w-3xl rounded-lg border-0 py-3 pl-3 text-base-900 shadow-sm ring-1 ring-inset ring-slate-200 placeholder:text-slate-400 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-600 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-base sm:leading-6" />
      </div>
    </div>

    <div>
      <button
        type="submit"
        @click.prevent="login"
        class="flex w-full justify-center rounded-lg border border-purple-500 bg-purple-500 px-4 py-3 text-base font-semibold text-white duration-200 ease-in-out hover:border-purple-600 hover:bg-purple-600">
        Sign in
      </button>
    </div>
  </form>
</template>
